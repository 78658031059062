.start-cont{
    position: relative;
    width: 700px;
    left: 10%;
    margin: auto;
  }
  
  .f_q {
    margin-left: 32.5%;
    margin-bottom: 20px;
    font-weight: bolder;
    color: #7e7e7e;
  }
  @media (max-width: 767px) {
   .start-cont{
    width: 70%;
    left: 0;
   }  
  }
  @media (max-width: 697px) {
    .start-cont{
     width: 90%;
     left: 0;
    }
}
@media (max-width: 448px) {
.contact-form{
    padding: 43px 6% 65px !important;
}
}